<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 50vw">
      <q-card-section class="row items-center">
        <div class="text-h6 text-center">
          {{ $t("Selected products") }}
        </div>

        <form-builder :schema="schema" class="col col-md-4" />

        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section v-if="items.length > 0">
        <q-list bordered separator>
          <q-item v-for="item in items" :key="item.id" v-ripple>
            <q-item-section avatar>
              <img
                  style="max-height: 50px; width: 60px; object-fit: contain"
                  :src="item.image || fallbackImage"
                  @error="onImageLoadFailure"
              />
            </q-item-section>

            <q-item-section>
              {{ `${item.name} (${item?._embedded?.productOffer?.id})` }}
            </q-item-section>

            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="item.count"
                :label="$t('Quantity')"
                @update:model-value="updateItem(item.id, 'count', $event)"
            />

            <q-item-section style="max-width: 80px" class="q-pl-sm">
              <q-btn
                  color="negative"
                  size="sm"
                  :label="$t('Delete')"
                  @click="handleDelete(item.id)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-section v-else class="q-pa-lg text-center text-subtitle1">
        {{ $t("No products") }}
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Discard')"
            @click="hide"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Add')"
            :disable="items.length <= 0"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'OrderAcceptanceModal',
  props: {
    warehouse: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      orderType: '',
      items: [],
      virtualShop: {}
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'order'
    ]),
    orderTypes () {
      return Object.values(this.appOptions.orderTypes)
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Order type'),
                wrapperStyleClasses: 'col-12',
                value: this.orderType,
                options: this.orderTypes,
                dense: true,
                disabledClean: true,
                required: true,
                customLabel: (orderType) => {
                  return orderType.label
                },
                onChange: (orderType) => {
                  this.orderType = orderType.type
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions([
      'loadShop',
    ]),
    ...mapMutations([
      'addErrorNotification',
      'setNewOrder',
      'setOrderProducts',
      'setNewDeliveryRequest',
      'updateOrder',
      'setNewAddress'
    ]),
    handleDeleteAll () {
      this.items = []
    },
    show (items) {
      this.items = [...items]
      this.isOpen = true
    },
    hide () {
      this.isOpen = false
    },
    save () {
      if (!this.orderType) {
        this.addErrorNotification('Please select order type!')
        return
      }
      // this.loadOffer({
      //   type: this.items[0]._embedded.productOffer
      // })
      // this.loadShop(this.$route.params.id)

      if(this.items[0]._embedded.productOffer._embedded.virtualShop){
        const test = this.items[0]._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()
        this.loadShop(test)
            .then(e=>{
              this.virtualShop = e
              this.setNewOrder({ type: this.orderType })
              this.updateOrder({
                _embedded: {
                  ...this.order._embedded,
                  shop: e,
                  warehouse: this.warehouse
                }
              })

              const products = this.items.map(productOffer => {
                return {
                  count: productOffer.count,
                  price: productOffer.price,
                  _embedded: {
                    shop: productOffer._embedded.shop,
                    productOffer: productOffer._embedded.productOffer
                  }
                }
              })

              this.setOrderProducts(products)
              this.setNewAddress()
              this.setNewDeliveryRequest()
              this.$router.push(`/outbound/orders/entity/${this.orderType}?hasInit=true`)
            })
      } else {
        this.setNewOrder({ type: this.orderType })
        this.updateOrder({
          _embedded: {
            ...this.order._embedded,
            shop: this.items[0]._embedded.shop,
            warehouse: this.warehouse
          }
        })

        const products = this.items.map(productOffer => {
          return {
            count: productOffer.count,
            price: productOffer.price,
            _embedded: {
              shop: productOffer._embedded.shop,
              productOffer: productOffer._embedded.productOffer
            }
          }
        })

        this.setOrderProducts(products)
        this.setNewAddress()
        this.setNewDeliveryRequest()
        this.$router.push(`/outbound/orders/entity/${this.orderType}?hasInit=true`)
      }
    },
    handleDelete (id) {
      this.items = this.items.filter(x => x.id !== id)
    },
    updateItem (id, field, event) {
      this.items = this.items.map(item => {
        if (item.id !== id) {
          return item
        }

        return { ...item, [field]: event }
      })
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    }
  }
}
</script>
